const classNames = {
    hiddenItem: 'hidden',
    filteredItem: 'filtered',
    filteringItem: 'filtering'
};

/**
 *
 * @param {HTMLElement} container
 *
 * @constructor
 */
export class Filter {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container - Element containing filter options (Could be .filter__tabs or .filter__select)
     */
    constructor(container) {
        if (container) {
            this.dom = {
                container,
                search: container.querySelector('[data-filter][type="text"]'),
                filters: container.querySelectorAll('[data-filter]'),
                targetContainer: document.body.querySelector(container.getAttribute('data-target-container'))
            };

            this.settings = {
                filterType:  container.getAttribute('data-action')
            };

            if (this.dom.targetContainer) {
                for (let i = 0; i < this.dom.filters.length; i++) {
                    const filter = this.dom.filters[i];
                    if (filter.tagName === 'SELECT') {
                        filter.addEventListener('change', e => this.filterHandler(e));

                        // Trigger change if filter.value is cached in the browser
                        setTimeout(() => {
                            if (filter.value != undefined) {
                                filter.dispatchEvent(new Event('change'));
                            }
                        }, 0);

                    } else if (filter.type === 'text') {
                        filter.addEventListener('keyup', e => this.filterHandler(e));
                    }
                }
            } else {
                window.console.warn('Can\'t find [data-target-container] on filter container: ', container);
            }
        } else {
            window.console.warn('Filter container missing');
        }
    }

    /**
     * Filter function that compares selected filter values with filter-values on filter-item.
     * Added hidden-class to items that hasn't got the selected filter value.
     */
    filterHandler() {
        const filterItems = this.dom.targetContainer.querySelectorAll('[data-filter-value]');

        this.dom.targetContainer.classList.add(classNames.filteringItem);
        this.dom.targetContainer.classList.add(classNames.filteredItem);

        setTimeout(() => {
            this.dom.targetContainer.classList.remove(classNames.filteringItem);
        }, 300);

        const filterValues = Filter.getSetValues(this.dom.filters);
        const searchItem = this.dom.search;
        const cleanSearchValue = searchItem ? searchItem.value.trim().toLowerCase() : '*';

        for (let i = 0; i < filterItems.length; i++) {
            const item = filterItems[i];
            const itemSearchValue = item.textContent.trim().toLowerCase();
            const itemSearchTagValues = item.hasAttribute('data-search-value') ? item.getAttribute('data-search-value').toLowerCase() : null;
            const itemFilterValues = item.getAttribute('data-filter-value').split(',')
                .map(value => value.trim().toLowerCase());
            let showItem = true;

            for (let i = 0; i < filterValues.length; i++) {
                const value = filterValues[i];
                const cleanFilterValue = value.trim().toLowerCase();

                if (cleanFilterValue != '*' && itemFilterValues.indexOf(cleanFilterValue) === -1) {
                    showItem = false;
                }
            }

            if (cleanSearchValue != '*' && itemSearchValue.indexOf(cleanSearchValue) === -1 && itemSearchTagValues.indexOf(cleanSearchValue) === -1) {
                showItem = false;
            }

            if (!filterValues.length) {
                this.dom.targetContainer.classList.remove(classNames.filteredItem);
            }

            if (!showItem) {
                item.classList.add(classNames.hiddenItem);
            } else {
                item.classList.remove(classNames.hiddenItem);
            }
        }
    }

    static getSetValues(filters) {
        const valArray = [];
        for (let i  = 0, len = filters.length; i < len; i++) {
            const filter = filters[i];
            if (filter.tagName === 'SELECT') {
                if (filter.value !== '*' && filter.value !== '') {
                    valArray.push(filter.value);
                }
            }
        }
        return valArray;
    }
}

export function setupFilters(selector = '[data-action*="filter"]') {
    const filterContainers = document.body.querySelectorAll(selector);

    for (let i = 0; i < filterContainers.length; i++) {
        void new Filter(filterContainers[i]);
    }
}
