import { onWindowResize, currentBreakpoint, currentBreakpointIndex } from './utils/windowResize';
import { onScroll, scrollTop } from './utils/scroll';
import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
// import { setupLazyLoading } from './utils/lazyImage';
import { setupAccordions } from './components/accordion';
import { setupVideos } from './components/video';
import { initVhUnitOverwrite, showHideElem } from './utils/helpers';
// import { version } from '../../package.json';
import { setupAnchorLinkScroll } from './components/anchors';
import { setupFilters } from './components/filter';
import { setupOverlay } from './components/overlay';
import { setupTabs } from './components/tabs';
import { setupSliders } from './components/slider';
import { watchFormFields } from './components/form';
import { setupUmbracoMemberLogin } from './components/umbraco-member';
import { scrollAnimations } from './utils/animation';
// import lazySizes from 'lazysizes';
// Below are example of how to use import libraries installed via npm.
// import sal from 'sal.js';

function init() {
    document.body.classList.remove('standby');

    lazySizes.cfg.loadMode = 1;
    lazySizes.cfg.preloadAfterLoad = false;
    lazySizes.autoSizer.checkElems();

    setupAnchorLinkScroll();

    setupNav('.nav');

    onReady(() => {
        initVhUnitOverwrite();

        setupAccordions();


        setupOverlay();

        setupVideos();

        setupTabs();

        setupSliders();

        setupUmbracoMemberLogin();

        watchFormFields();

        showHideElem();

        setupFilters();

        scrollAnimations();

    });

    // Simulated CookieBot function-call via GTM
    //window.showCookieBanner();

}

init();
