import Flickity from '../../../node_modules/flickity';
import { currentWindowWidth, onWindowResize } from '../utils/windowResize';

/**
 * The Slider class uses the cssAnimateNewHeight function to reveal hidden content.
 * Default element selector is data-action*="slider"
 *
 * @param {HTMLElement} container
 * @constructor
 */
export class Slider {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */
    constructor(container) {
        this.container = container;

        if (this.container) {
            const mobileMode = !(currentWindowWidth > 1023);

            this.flkty = new Flickity(this.container, {
                cellAlign: 'left',
                prevNextButtons: false,
                percentPosition: false,
                autoPlay: parseInt(this.container.getAttribute('data-autoplay')) || false,
                wrapAround: false,
                selectedAttraction: mobileMode ? 0.025 : 0.02,
                friction: 0.20,
                pageDots: false,
                draggable: true,
                adaptiveHeight: false,
                // lazyLoad: mobileMode ? 1 : 3
                // dragThreshold: 10,
                // freeScroll: true,
            });

            this.bindEvents();
        }
    }

    bindEvents() {
        const scope = this;
        //Change cell position on resize, when width is smaller than 768px
        onWindowResize(
            function alignSlider() {
                const mobileMode = !(currentWindowWidth > 1023);

                scope.flkty.option({ cellAlign: 'left' });
                scope.flkty.reloadCells();
            }
        );

        //Custom navigation
        const cellsButtonGroup = this.container.parentElement.querySelector('.slider__nav');

        const previousButton = this.container.parentElement.querySelectorAll('.btn--previous');
        const nextButton = this.container.parentElement.querySelectorAll('.btn--next');

        // previous
        if (previousButton) {
            for (let i = 0; i < previousButton.length; i++) {

                previousButton[i].addEventListener('click', function () {
                    scope.flkty.previous();
                });
            }
        }
        // next
        if (nextButton) {
            for (let i = 0; i < nextButton.length; i++) {

                nextButton[i].addEventListener('click', function () {
                    scope.flkty.next();
                });
            }
        }


        function disableButton() {
            if (previousButton) {

                for (let i = 0; i < previousButton.length; i++) {
                    if (scope.flkty.selectedIndex == 0) {
                        previousButton[i].disabled = true;
                    }
                    else {
                        previousButton[i].disabled = false;
                    }
                }
            }

            if (nextButton) {
                for (let i = 0; i < nextButton.length; i++) {

                    if (scope.flkty.selectedIndex == cellsButtonGroup.children.length - 1) {
                        nextButton[i].disabled = true;
                    }
                    else {
                        nextButton[i].disabled = false;
                    }
                }
            }



        }

        disableButton();

        if (cellsButtonGroup) {
            const navButtons = Array.from(cellsButtonGroup.children);

            // update buttons on select
            this.flkty.on('select', function () {
                const previousSelectedButton = cellsButtonGroup.querySelector('.is-selected');
                const selectedButton = cellsButtonGroup.children[scope.flkty.selectedIndex];
                previousSelectedButton.classList.remove('is-selected');
                selectedButton.classList.add('is-selected');

                disableButton();

            });

            // cell select
            cellsButtonGroup.addEventListener('click', function (event) {
                if (!event.target.matches('[data-nav="dot"]')) {
                    return;
                }
                const index = navButtons.indexOf(event.target);
                scope.flkty.select(index, false, false);
            });
        }



    }
}

export function setupSliders(selector = '[data-action*="slider"]') {
    const sliders = document.body.querySelectorAll(selector);

    for (let i = 0; i < sliders.length; i++) {
        void new Slider(sliders[i]);
    }


}
