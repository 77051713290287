import { serialize, validateForm } from '../components/form';
import { insertElementAfter } from '../utils/elementManipulation';

/**
 * Sets up Form submit eventhandler used when submitting form for login
 *
 * @param {string} selector - querySelector string
 */
export function setupUmbracoMemberLoginForm (selector = 'form[data-action*="umbraco-member-login"]') {
    const loginForm = document.querySelector(selector);

    function loginSubmit(e) {
        e.preventDefault();

        const form = e.target;
        const XHR = new XMLHttpRequest();
        const formData = serialize(form);

        form.classList.add('loading');

        clearFormMessage(form);

        XHR.onreadystatechange = function () {
            if (this.readyState === this.DONE) {
                if (XHR.onreadystatechange) {
                    XHR.onreadystatechange = null;

                    if (this.status == 200) {
                        if (form.hasAttribute('data-login-url') && form.getAttribute('data-login-url') != '') {
                            window.location = form.getAttribute('data-login-url');
                        } else {
                            window.location.reload(false);
                        }
                    } else {
                        const responseJson = JSON.parse(this.responseText);
                        if (responseJson.Message === 'Could not log in user') {
                            responseJson.Message = 'Kunne ikke logge på bruger';
                        }
                        displayFormMessage(responseJson.Message, form);
                    }

                    form.classList.remove('loading');
                }
            }
        };

        XHR.open('POST', '/umbraco/api/memberapi/login', true);
        XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        XHR.send(formData);
    }

    if (loginForm) {
        loginForm.addEventListener('submit', loginSubmit);
    }
}

/**
 * Sets up click eventhandler for log out
 *
 * @param {string} selector - querySelector string
 */
export function setupUmbracoMemberLogoutTriggers (selector = '[data-action*="umbraco-member-logout"]') {
    const logoutTriggers = document.querySelectorAll(selector);

    function logout(e) {
        e.preventDefault();

        const trigger = e.target;
        const XHR = new XMLHttpRequest();

        XHR.onreadystatechange = function () {
            if (this.readyState === this.DONE) {
                if (XHR.onreadystatechange) {
                    XHR.onreadystatechange = null;

                    if (this.status == 200) {
                        if (trigger.hasAttribute('data-logout-url') && trigger.getAttribute('data-logout-url') != '') {
                            window.location = trigger.getAttribute('data-logout-url');
                        } else {
                            window.location.reload(false);
                        }
                    } else {
                        console.warn('Logout Error');
                    }
                }
            }
        };

        XHR.open('GET', '/umbraco/api/memberapi/logout', true);
        XHR.send();
    }

    for (let i = 0; i < logoutTriggers.length; i++) {
        logoutTriggers[i].addEventListener('click', logout);
    }
}

/**
 * Sets up Form submit eventhandler used when submitting form for login
 *
 * @param {string} selector - querySelector string
 */
export function setupUmbracoMemberResetPasswordForm (selector = 'form[data-action*="umbraco-member-reset-password"]') {
    const passwordRecoveryForm = document.querySelector(selector);

    function recoverySubmit(e) {
        e.preventDefault();

        const form = e.target;
        const XHR = new XMLHttpRequest();
        const formData = serialize(form);

        form.classList.add('loading');

        clearFormMessage(form);

        XHR.onreadystatechange = function () {
            if (this.readyState === this.DONE) {
                if (XHR.onreadystatechange) {
                    XHR.onreadystatechange = null;

                    if (this.status == 200) {
                        if (form.hasAttribute('data-success-text') && form.getAttribute('data-success-text') != '') {

                            displaySuccessMessage(form);
                            form.style.display = 'none';

                        } else {
                            window.location.reload(false);
                        }
                    } else {
                        const responseJson = JSON.parse(this.responseText);
                        displayFormMessage(responseJson.Message, form);
                    }

                    form.classList.remove('loading');
                }
            }
        };

        XHR.open('POST', '/umbraco/api/memberapi/resetpassword', true);
        XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        XHR.send(formData);
    }

    if (passwordRecoveryForm) {
        passwordRecoveryForm.addEventListener('submit', recoverySubmit);
    }
}

/**
 * Sets up Form submit eventhandler used when submitting form for change password
 *
 * @param {string} selector - querySelector string
 */
export function setupUmbracoMemberChangePasswordForm (selector = 'form[data-action*="umbraco-member-change-password"]') {
    const passwordRecoveryForm = document.querySelector(selector);

    function changePasswordSubmit(e) {
        e.preventDefault();

        const form = e.target;
        const XHR = new XMLHttpRequest();
        const formData = serialize(form);

        form.classList.add('loading');

        clearFormMessage(form);

        XHR.onreadystatechange = function () {
            if (this.readyState === this.DONE) {
                if (XHR.onreadystatechange) {
                    XHR.onreadystatechange = null;

                    if (this.status == 200) {
                        if (form.hasAttribute('data-success-text') && form.getAttribute('data-success-text') != '') {

                            displaySuccessMessage(form);
                            form.style.display = 'none';

                        } else {
                            window.location.reload(false);
                        }
                    } else {
                        const responseJson = JSON.parse(this.responseText);
                        displayFormMessage(responseJson.Message, form);
                    }

                    form.classList.remove('loading');
                }
            }
        };

        XHR.open('POST', '/umbraco/api/memberapi/changepassword', true);
        XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        XHR.send(formData);
    }

    if (passwordRecoveryForm) {
        passwordRecoveryForm.addEventListener('submit', changePasswordSubmit);
    }
}


/**
 * Sets up Form submit eventhandler used when submitting form for edit member profile
 *
 * @param {string} selector - querySelector string
 */
export function setupUmbracoMemberEditProfileForm (selector = 'form[data-action*="umbraco-member-edit"]') {
    const editMemberForm = document.querySelector(selector);

    function memberEditSubmit(e) {
        e.preventDefault();

        const form = e.target;
        const XHR = new XMLHttpRequest();
        const formData = serialize(form);

        form.classList.add('loading');

        clearFormMessage(form);

        XHR.onreadystatechange = function () {
            if (this.readyState === this.DONE) {
                if (XHR.onreadystatechange) {
                    XHR.onreadystatechange = null;

                    if (this.status == 200) {
                        if (form.hasAttribute('data-success-text') && form.getAttribute('data-success-text') != '') {

                            displaySuccessMessage(form);
                            form.style.display = 'none';

                        } else {
                            window.location.reload(false);
                        }
                    } else {
                        const responseJson = JSON.parse(this.responseText);
                        displayFormMessage(responseJson.Message, form);
                    }

                    form.classList.remove('loading');
                }
            }
        };

        XHR.open('POST', '/umbraco/api/memberapi/updateprofile', true);
        XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        XHR.send(formData);
    }

    if (editMemberForm) {
        editMemberForm.addEventListener('submit', memberEditSubmit);
    }
}


function displayFormMessage(message, parentElem, selector = '.form__message--last') {
    let formMessage = parentElem.querySelector(selector);

    if (formMessage) {
        formMessage.textContent = message;
    } else {
        formMessage = document.createElement('span');
        formMessage.classList.add('form__message');
        formMessage.classList.add('form__message--error');
        formMessage.classList.add('form__message--last');
        formMessage.textContent = message;

        parentElem.appendChild(formMessage);
    }
}

function displaySuccessMessage(form, selector = '.form__message--last') {
    let formMessage = form.querySelector(selector);

    if (formMessage) {
        formMessage.textContent = form.getAttribute('data-success-text');
    } else {
        formMessage = document.createElement('span');
        formMessage.classList.add('form__message');
        formMessage.textContent = form.getAttribute('data-success-text');

        insertElementAfter(formMessage, form);
    }
}

function clearFormMessage(form, selector = '.form__message') {
    const formMessage = form.querySelector(selector);

    if (formMessage) {
        formMessage.textContent = '';
    }
}


export function setupUmbracoMemberLogin() {
    setupUmbracoMemberLoginForm();
    setupUmbracoMemberLogoutTriggers();
    setupUmbracoMemberResetPasswordForm();
    setupUmbracoMemberChangePasswordForm();
    setupUmbracoMemberEditProfileForm();
}
