gsap.registerPlugin(ScrollTrigger);

export const transitionEndEventName = detectStyleDeclatationName();
export const animationEndEventName = detectStyleDeclatationName('animation');


/**
 * Detects which prefixed eventName is in CSSStyleDeclaration
 *
 * @param {string} eventTypeName - choose 'transition' or 'animation'
 * @returns {string}
 */
export function detectStyleDeclatationName(eventTypeName = 'transition') {
    const transitions = {
        transition: `${eventTypeName}end`,
        OTransition: `o${eventTypeName}end`,
        MozTransition: `${eventTypeName}end`,
        WebkitTransition: `webkit${eventTypeName}end`
    };

    const el = document.createElement('div');

    for (const t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
    return '';
}

/**
 *
 * @param {HTMLElement} container - container to animate height of
 * @param {HTMLElement} classTarget [default = container] - target element that gets class
 * @param {Boolean} classOnBoth [default = false] - set class on both container and classTarget
 */
export function cssAnimateNewHeight(container, classTarget = container, classOnBoth = false) {
    let newHeight;
    const prevHeight = container.offsetHeight;

    const delayedClose = () => {
        if (classOnBoth) {
            container.classList.remove('open');
            classTarget.classList.remove('open');
        } else {
            classTarget.classList.remove('open');
        }

        container.removeEventListener(transitionEndEventName, delayedClose);
    };

    container.removeAttribute('style');

    if (classTarget.classList.contains('open')) {
        newHeight = 0;
        container.addEventListener(transitionEndEventName, delayedClose);

        if (classOnBoth) {
            classTarget.classList.remove('open');
        }
    } else {
        classTarget.classList.add('open');

        if (classOnBoth) {
            container.classList.add('open');
        }

        newHeight = container.offsetHeight;
    }

    container.style.height = `${prevHeight}px`;

    setTimeout(() => {
        container.style.height = `${newHeight}px`;
    }, 10);
}

export function scrollAnimations() {

    //Scroll trigger for text-image
    const textImages = document.querySelectorAll('.text-image--animate');
    for (let i = 0; i < textImages.length; i++) {
        const textImage = textImages[i];
        const txt = textImage.querySelector('.text-image__text');
        const img = textImage.querySelector('img');

        if (txt && img) {
            const isReversed = textImage.querySelector('.grid-row').classList.contains('grid-row--reverse');

            const timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: textImage,
                    toggleActions:'restart none none none',
                    start: 'top bottom',
                    // markers: true,
                }
            });


            timeLine.from(txt,{
                x:isReversed ? 500 : -500,
                alpha:0,
                ease: 'power3.out',
                duration:.7,

            });
            timeLine.from(img,{
                x:isReversed ? -500 : 500,
                alpha:0,
                ease: 'power3.out',
                duration:.7,

            },'-=.7');

            img.addEventListener('lazyloaded', () => {
                ScrollTrigger.refresh();
            });


        }
    }



    //Scroll trigger for events
    const events = document.querySelectorAll('.event');

    for (let i = 0; i < events.length; i++) {
        const event = events[i];
        const txt = event.querySelector('.col.col--sm-6.h--pad.v--pad');
        const img = event.querySelector('img');

        if (txt && img) {

            const timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: event,
                    toggleActions:'restart none none none',
                    start: 'top bottom',
                    // markers: true,
                }
            });


            timeLine.from(txt,{
                x:500,
                alpha:0,
                ease: 'power2.out',
                duration:.7,
            });
            timeLine.from(img,{
                x:-500,
                alpha:0,
                ease: 'power2.out',
                duration:.7,

            },'-=.7');

            img.addEventListener('lazyloaded', () => {
                ScrollTrigger.refresh();
            });


        }
    }




    //Scroll trigger for events
    const sliders = document.querySelectorAll('.slider');

    for (let i = 0; i < sliders.length; i++) {
        const slider = sliders[i];
        const sliderItems = slider.querySelectorAll('.slider__cell');

        if (sliderItems) {

            const timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: sliders,
                    toggleActions:'play none none none',
                    start: 'top center',
                    // markers: true,
                }
            });

            timeLine.from(sliderItems,{
                x:500,
                alpha:0,
                ease: 'power2.out',
                duration:0.7,
                stagger: 0.1,
            });

        }
    }


    //Memberlist
    // const memberLists = document.querySelectorAll('.participants--list--large');
    // if (memberLists) {
    //     for (let i = 0; i < memberLists.length; i++) {
    //         const memberList = memberLists[i];

    //         console.log(memberList);

    //         const members = memberList.querySelectorAll('.participants--list__participant');

    //         if (members) {
    //             const timeLine = gsap.timeline({
    //                 scrollTrigger: {
    //                     trigger: memberList,
    //                     toggleActions:'play none none none',
    //                     start: 'bottom bottom',
    //                     // markers: true,
    //                 }
    //             });


    //             timeLine.from(members,{
    //                 scale:0,
    //                 alpha:0,
    //                 ease: 'power2.out',
    //                 duration:0.3,
    //                 stagger: 0.01,

    //             });



    //         }
    //     }
    // }


    // const members = memberList.querySelectorAll('.participants--list__participant');
    // if (members) {
    //     ScrollTrigger.batch('.participants--list__participant',{
    //         onEnter: batch => gsap.from(batch, {alpha:1,stagger:0.1})
    //     });
    // }


}

